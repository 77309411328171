<template>
  <main>
    <div>
      <!-- <h1 class="text-2xl mt-5 font-bold text-blue-800">
          Support Form
        </h1> -->
      <div class="flex flex-wrap xl:-mx-4 pb-5 ">
        <!-- <div class="w-full xl:mb-4 xl:px-4">
            <p
              :class="errorMssgColor ? 'text-red-800' : 'text-green-600'"
              v-if="errorMssg"
            >
              {{ errorMssg }}
            </p>
          </div> -->

        <div class="md:w-1/3 xl:my-4 xl:px-4 mt-5 mx-auto">
          <div
            class="w-full md:w-full p-5 border-2 border-gray-100 bg-white rounded-lg"
          >
            <!-- <div> -->
            <form class="w-full max-w-full" @submit.prevent="checkStaffCode">
              <div class="flex flex-wrap -mx-3 mb-3">
                <div class="w-full md:w-full px-3 mb-6 md:mb-0">
                  <label
                    class="block  tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-first-name"
                  >
                    Staff Code <span class="text-red-800">*</span>
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    v-model="staffCode"
                    placeholder="Enter Staff Code"
                  />
                </div>
              </div>
              <div class="w-full flex mt-5">
                <div class="my-3 w-80">
                  <button
                    class="py-3 mr-5 px-10 bg-yellow-300 rounded-md font-semibold text-black text-sm"
                    type="button"
                    @click="resetForm"
                  >
                    Reset
                  </button>
                  <button
                    class="py-3 px-10 bg-blue-800 rounded-md font-semibold text-white text-sm"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <span class="loader-line" v-if="isLoadingInline"></span>
        </div>

        <div
          v-if="promptModal"
          class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
        >
          <div
            class="w-full md:w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4"
          >
            <div class="block" v-if="!error">
              <h4 class="font-semibold">
                Staff Information for {{ staffCode }}
              </h4>
              <p class="my-4">
                <span class="font-bold">Last name:</span> {{ staffLastName }}
              </p>
              <p class="my-4">
                <span class="font-bold">First name:</span> {{ staffFirstName }}
              </p>

              <button
                @click="promptModal = false"
                class="
                      block
                      uppercase
                      mx-auto
                      hover:bg-transparent
                      hover:text-red-600
                      bg-red-600
                      focus:shadow-outline
                      focus:outline-none
                      text-white text-xs
                      font-bold
                      py-2
                      px-4
                      rounded
                    "
                style="width:inherit"
              >
                Close
              </button>
            </div>
            <div class="block" v-else>
              <h3 class="text-center">{{ alertMessage.toUpperCase() }}</h3>
              <button
                @click="promptModal = false"
                class="
                      block
                      uppercase
                      mx-auto
                      hover:bg-transparent
                      hover:text-red-600
                      bg-red-600
                      focus:shadow-outline
                      focus:outline-none
                      text-white text-xs
                      font-bold
                      py-2
                      px-4
                      rounded
                      mt-10
                    "
                style="width:inherit"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "TractionStaffVerificationPortal",
  data() {
    return {
      moment,
      isLoadingInline: false,
      staffCode: "",
      promptModal: false,
      errorMssgColor: "",
      staffLastName: "",
      staffFirstName: "",
      alertMessage: "",
      error: false,
    };
  },
  async mounted() {},
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    //   await this.fetchIssues();
  },
  methods: {
    async checkStaffCode() {
      this.isLoadingInline = true;
      let payload = { staffCode: this.staffCode };
      try {
        let res = await this.$store.dispatch(
          "FETCH_STAFF_BY_STAFF_CODE",
          payload
        );

        console.log(res);

        if (res.status) {
          this.isLoadingInline = false;
          this.promptModal = true;
          this.staffLastName = res.data.firstName;
          this.staffFirstName = res.data.lastName;
          this.error = false;
        } else {
          this.error = true;
          this.alertMessage = res.message;
          this.promptModal = true;
          this.isLoadingInline = false;
        }
      } catch (error) {
        console.log(error);
      }
      // this.isLoading = false;
    },
    resetForm() {
      this.staffCode = "";
    },
  },
};
</script>

<style scoped>
.tel::-webkit-inner-spin-button,
.tel::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.tel {
  -moz-appearance: textfield;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
.inline-loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 0.5em;
  height: 0.5em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  -moz-animation: spinner 1500ms infinite linear;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.icon-cancel {
  position: absolute;
  top: 8%;
  right: 1%;
}

.icon-sm-cancel {
  position: absolute;
  top: 15%;
  right: 76.5%;
}

.icon-sm-clear-cancel {
  position: absolute;
  top: 15%;
  right: 42%;
}

.loader-line {
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  /* margin: 100px auto; */
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  display: block;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #003282;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
</style>
